import React, { useState } from "react";
import "./index.scss";
import { Image, Button } from "antd";
import { MenuOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { IconSFTP } from "images";

function SFTPCatalog() {
    const navigate = useNavigate();
    const { id } = useParams();
    const navigateToFields = () => {
        navigate(`/dashboard/sources/setup/${"sftp"}/${id}`);
    };

    const handleBackClick = () => {
        // Navigate back to the previous page
        navigate(-1); // Go back one step
    };

    const handleConnectionClick = () => {
        // Navigate back to the previous page
        navigate(-2); // Go back one step
    };

    return (
        <div className="SFTPCatalog">
            <div className="SFTPCatalog__Fixed">
                <div className="SFTPCatalog__sourceText">
                    <div>
                        <Image
                            src={IconSFTP}
                            preview={false}
                            style={{ marginRight: "10px", width: "65px", height: "65px" }}
                        />
                        <span style={{ marginLeft: "15px" }}>Secure File Transfer Protocol</span>
                    </div>
                </div>
                <div className="SFTPCatalog__bar">
                    <div>
                        <Button
                            onClick={() => navigateToFields()}
                            className="SFTPCatalog__button"
                        >
                            Add source
                            <ArrowRightOutlined className="SFTPCatalog__btnImage" />
                        </Button>
                    </div>
                </div>

                <div className="SFTPCatalog__newSource">
                    <Button className="SFTPCatalog__newText">Source</Button>
                </div>
                <div className="SFTPCatalog__exploreBar">
                    <div className="SFTPCatalog__exploreText">
                        SFTP, or Secure File Transfer Protocol, is a secure file transfer protocol that uses secure shell encryption to provide a high level of security for sending and receiving file transfers.
                    </div>
                </div>
                <div className="SFTPCatalog__bottomBar"></div>
            </div>
            <div className="SFTPCatalog__mainContainer">
                <div className="SFTPCatalog__flexDiv">
                    <div className="SFTPCatalog__container1">
                        <h6 className="SFTPCatalog__cardHeading1">
                            Compatible destination types
                        </h6>
                        <div className="SFTPCatalog__textFlex">
                            <div className="SFTPCatalog__cardIcon">
                                <MenuOutlined />
                            </div>
                            <div className="SFTPCatalog__cardText">Storage</div>
                        </div>
                        <p className="SFTPCatalog__rawText">
                            Destinations for storing raw data.
                            {/* <a className="SFTPCatalog__anchorTag">Learn more</a> */}
                        </p>
                    </div>
                    <div className="SFTPCatalog__container2">
                        <div className="SFTPCatalog__containFlex">
                            <div className="SFTPCatalog__line"></div>
                            <div className="SFTPCatalog__justDiv">
                                <h6 className="SFTPCatalog__cardHeading2">
                                    Set up requirements
                                </h6>
                                <p className="SFTPCatalog__setText1">
                                    * An enabled SFTP acount
                                </p>
                                {/* <p className="SFTPCatalog__setText1">
                  * Ideally, the account should have View All Records
                  permission. If you aren’t sure, ask you Salesforce
                  administrator for help.
                </p> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="SFTPCatalog__flexDiv1">
                    <div className="SFTPCatalog__container3">
                        <h6 className="SFTPCatalog__overviewHeading1">Overview</h6>
                        <p className="SFTPCatalog__overviewText">
                            SFTP is similar to FTPS in that it uses AES and other algorithms to secure data as it travels between different systems. SFTP also provides several methods to fulfill the authentication of a connection such as user IDs and passwords, SSH keys, or combinations of these. SFTP is capable of helping enterprises achieve file transfer compliance as required for HIPAA, GDPR, and other regulatory regimes.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SFTPCatalog;
