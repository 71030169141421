import Uploads from "components/Datasets/Onboarding/Uploads";
import CreateB2BPlaybook from "components/Playbooks/CreateB2BPlaybook";
import PreviewPlaybookAds from "components/Playbooks/CreateB2BPlaybook/PreviewPlaybookAds";
import CreatePlaybook from "components/Playbooks/CreatePlaybook";
import MyPlaybooks from "components/Playbooks/MyPlaybooks";
import NewPlaybooks from "components/Playbooks/NewPlaybooks";
import CategoryPlaybooks from "components/Playbooks/NewPlaybooks/CategoryPlaybooks";
import PlaybookDetails, {
  PlaybooksDetailPage,
} from "components/Playbooks/PlaybookDetails";
import EditPlaybookDetails from "components/Playbooks/PlaybookDetails/EditPlaybookDetails";
import Proposals from "components/Proposals";
import ProposalDetails from "components/Proposals/ProposalDetails";
import CreateWorkspace from "components/Workspaces/CreateWorkspace";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Chat from "screens/Chat";
import ChatUI from "screens/Chat/ChatUI";
import Datasets from "screens/Datasets";
import DeeplinkExternal from "screens/DeeplinkExternal";
import DeeplinkExternalProposalChat from "screens/DeeplinkExternalProposalChat";
import DeeplinkExternalSellerListing from "screens/DeeplinkExternalSellerListing";
import DeeplinkInvite from "screens/DeeplinkInvite";
import QuizFlow from "screens/QuizFlow";
import Settings from "screens/Settings";
import Billing from "screens/Settings/Billing";
import ChangePassword from "screens/Settings/ChangePassword";
import Company from "screens/Settings/CompayDetails";
import NotificationSettings from "screens/Settings/NotificationSettings";
import Portfolio from "screens/Settings/Portfolio";
import Profile from "screens/Settings/Profile";
import SwitchRole from "screens/Settings/SwitchRole";
import CatalogList from "components/Datasets/CatalogList";
import UserManagement from "screens/Settings/UserManagement";
import { setExternalLinkSegmentParams } from "store/modules/auth/action";
import {
  ForgotPassword,
  ResetPassword,
  Signin,
  SignupInfo,
  SignupOptions,
  SignupPhoneCode,
  SignupPhoneNumber,
  SocialSignin,
  WelcomeBack,
} from "../screens";
import DashBoard from "../screens/Dashboard";
import V3EditNavBar from "screens/Settings/V3EditNavBar";
import Marketplaces from "components/Workspaces/Marketplaces";
import EditMarketplace from "components/Workspaces/EditMarketplace";
import SalesforceDetails from "components/Datasets/SalesForceDetails";
import SalesForceDestinationDetails from "components/Datasets/SalesForceDestinationDetails";
import ProcessingFields from "components/Datasets/ProcessingFields";
import DestinationProcessingFields from "components/Datasets/DestinationProcessingFields";
import ApiConnectorDetails from "components/Datasets/ApiConnectorDetails";
import ConnectorSettings from "components/Datasets/ConnectorSettings";
import CreateSource from "components/Datasets/ApiConnector/CreateSource";
import AvailableFlows from "components/Datasets/Onboarding/Uploads/Salesforce/AvailableFlows";
import ProcessingSteps from "components/Datasets/Onboarding/Uploads/ProcessingSteps";
import Destinations from "components/Datasets/Onboarding/Destinations";
import ConnectorOverview from "components/Datasets/ConnectorOverview";
import DestinationOverview from "components/Datasets/DestinationOverview";
import GoogleDriveCatalog from "components/Datasets/GoogleDriveCatalog";
import ZendeskCatalog from "components/Datasets/ZendeskCatalog";
import FacebookAdsCatalog from "components/Datasets/FacebookAdsCatalog";
import ConnectFacebookAds from "components/Datasets/FacebookAdsCatalog/Create/Screen1";
import SelectFacebookAccount from "components/Datasets/FacebookAdsCatalog/Create/Screen2";
import SQLSchemaName from "components/Datasets/FacebookAdsCatalog/Create/Screen3";
import PermissionsVerified from "components/Datasets/FacebookAdsCatalog/Create/Screen4";
import YouareConnectedtoFacebookAds from "components/Datasets/FacebookAdsCatalog/Create/Screen5";
import PostgresCatalog from "components/Datasets/PostgresCatalog";
import ModelCreation from "components/Datasets/ModelCreation";
import GoogleAdsCatalog from "components/Datasets/googleAdsCatalog";
import ModelOverview from "components/Datasets/ModelOverview";
import ModelSettings from "components/Datasets/ModelSettings";
import ModelQueryBuilder from "components/Datasets/ModelQueryBuilder";
import StepsController from "components/Datasets/NewUI/StepsController";
import BuildList from "components/Datasets/NewUI/BuildList";
import SelectSource from "components/Datasets/NewUI/BuildList/SelectSource";
import PasteQuery from "components/Datasets/NewUI/BuildList/PasteQuery";
import AddExclusions from "components/Datasets/NewUI/AddExclusions";
import PreviewYourList from "components/Datasets/NewUI/PreviewYourList";
import SaveDatasetDetails from "components/Datasets/NewUI/SelectYourDestinations/SaveDatasetDetails";
import SelectYourDestinations from "components/Datasets/NewUI/SelectYourDestinations";
import AddExclusionsPasteQuery from "components/Datasets/NewUI/AddExclusions/AddExclusionPasteQuery";
import AddExclustionSelectSource from "components/Datasets/NewUI/AddExclusions/AddExclusionsSelectSource";
import TransformYourData from "components/Datasets/NewUI/TransformYourData";
import TransformDataSelectSource from "components/Datasets/NewUI/TransformYourData/TransformDataSelectSource";
import TransformDataPasteQuery from "components/Datasets/NewUI/TransformYourData/TransformDataPasteQuery";
import DestinationCatalogList from "components/Datasets/DestinationCatalogList";
import LeadsAPICatalog from "components/Datasets/LeadsAPICatalog";
import LeadsAPIDestinationCatalog from "components/Datasets/LeadsAPIDestinationCatalog";
import MessageMediaCatalog from "components/Datasets/MessageMediaCatalog";
import CsvOverview from "components/Datasets/CsvOverview";
import Tasks from "components/Datasets/Tasks";
import Explorer from "components/Datasets/Explorer";
import Unified from "components/Datasets/Explorer/Unified";
import CSVMappings from "components/Datasets/Onboarding/CSVMappings";
import Model from "components/Datasets/Model";
import AIModelOverview from "components/Datasets/AIModelOverview";
import CSVOverviewCatalog from "components/Datasets/Onboarding/CSVOverviewCatalog";
import FieldMapper from "components/Datasets/CsvOverview/FieldMapper";
import DataAxleDestinationCatalog from "components/Datasets/DataAxleDestinationCatalog";
import DeeplinkAudienceInvite from "screens/DeepLinkAudienceInvite";
import DeeplinkSourceInvite from "screens/DeepLinkSourceInvite";
import ZeroBounceDestinationCatalog from "components/Datasets/ZeroBounceDestinationCatalog";
import SFTPCatalog from "components/Datasets/SFTPCatalog";

function AppRoutes() {
  const authReducer = useSelector((state) => state.authReducer);
  const userSession = authReducer.userSession;
  const dispatch = useDispatch();
  const search = useLocation().search;

  useEffect(() => {
    const searchParams = new URLSearchParams(search);
    if (searchParams != null && searchParams != "") {
      const resultJSON = JSON.parse(
        '{"' +
        decodeURI(
          searchParams.toString().replace(/&/g, '","').replace(/=/g, '":"')
        ) +
        '"}'
      );
      window.analytics.page(
        {},
        {
          context: { campaign: resultJSON },
        }
      );
      dispatch(setExternalLinkSegmentParams(resultJSON));
    }
  }, []);

  return (
    <Routes>
      <Route
        path={APP_ROUTES.LANDING.path}
        element={
          <PublicElement>
            <Signin />
          </PublicElement>
        }
      />

      <Route
        path={APP_ROUTES.SIGNIN.path}
        element={
          <PublicElement>
            <Signin />
          </PublicElement>
        }
      />
      <Route
        path={APP_ROUTES.SOCIAL_SIGNIN.path}
        element={
          <PublicElement>
            <SocialSignin />
          </PublicElement>
        }
      />
      <Route
        path={APP_ROUTES.WELCOME_BACK.path}
        element={
          <PublicElement>
            <WelcomeBack />
          </PublicElement>
        }
      />
      <Route
        path={APP_ROUTES.SIGNUP.path}
        element={
          <PublicElement>
            <SignupOptions />
          </PublicElement>
        }
      />
      <Route
        path={APP_ROUTES.SIGNUP_INFO.path}
        element={
          <PublicElement>
            <SignupInfo />
          </PublicElement>
        }
      />
      <Route
        path={APP_ROUTES.SIGNUP_ADD_PHONE.path}
        element={
          <PublicElement>
            <SignupPhoneNumber />
          </PublicElement>
        }
      />
      <Route
        path={APP_ROUTES.SIGNUP_VERIFY_PHONE.path}
        element={
          <PublicElement>
            <SignupPhoneCode />
          </PublicElement>
        }
      />
      <Route
        path={APP_ROUTES.FORGOT_PASSWORD.path}
        element={
          <PublicElement>
            <ForgotPassword />
          </PublicElement>
        }
      />
      <Route
        path={APP_ROUTES.RESET_PASSWORD.path}
        element={
          <PublicElement>
            <ResetPassword />
          </PublicElement>
        }
      />

      {/* Protected Routes */}
      <Route
        path={APP_ROUTES.DASHBOARD.path}
        element={
          <ProtectedElement>
            <DashBoard />
          </ProtectedElement>
        }
      >
        <Route
          index
          element={
            <Navigate
              to={
                userSession?.accountRole === "buyer"
                  ? "datasets/explorer"
                  : "datasets/explorer"
              }
            />
          }
        />

        <Route
          path={APP_ROUTES.CATALOG_LIST.path}
          element={
            <ProtectedElement>
              <CatalogList />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.DESTINATION_CATALOG_LIST.path}
          element={
            <ProtectedElement>
              <DestinationCatalogList />
            </ProtectedElement>
          }
        />
        {/* <Route
          path={APP_ROUTES.CSV_ONBOARDING.path}
          element={
            <ProtectedElement>
              <ProcessingSteps />
            </ProtectedElement>
          }
        /> */}

        <Route
          path={APP_ROUTES.SALES_FORCE.path}
          element={
            <ProtectedElement>
              <SalesforceDetails />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.SALES_FORCE_DESTINATION.path}
          element={
            <ProtectedElement>
              <SalesForceDestinationDetails />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.LEADS_API_DESTINATION.path}
          element={
            <ProtectedElement>
              <LeadsAPIDestinationCatalog />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.DATA_AXLE_DESTINATION.path}
          element={
            <ProtectedElement>
              <DataAxleDestinationCatalog />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.ZERO_BOUNCE_DESTINATION.path}
          element={
            <ProtectedElement>
              <ZeroBounceDestinationCatalog />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.GOOGLE_DRIVE.path}
          element={
            <ProtectedElement>
              <GoogleDriveCatalog />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.POSTGRES.path}
          element={
            <ProtectedElement>
              <PostgresCatalog />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.ZENDESK.path}
          element={
            <ProtectedElement>
              <ZendeskCatalog />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.LEADS_API.path}
          element={
            <ProtectedElement>
              <LeadsAPICatalog />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.FACEBOOKADS.path}
          element={
            <ProtectedElement>
              <FacebookAdsCatalog />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.MESSAGE_MEDIA.path}
          element={
            <ProtectedElement>
              <MessageMediaCatalog />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.SFTP.path}
          element={
            <ProtectedElement>
              <SFTPCatalog />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.GOOGLE_ADS.path}
          element={
            <ProtectedElement>
              <GoogleAdsCatalog />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.CSV_CATALOG.path}
          element={
            <ProtectedElement>
              <CSVOverviewCatalog />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.PROCESSING_FIELDS.path}
          element={
            <ProtectedElement>
              <ProcessingFields />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.DESTINATION_PROCESSING_FIELDS.path}
          element={
            <ProtectedElement>
              <DestinationProcessingFields />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.FACEBOOK_ADS_CREATE_SCREEN1.path}
          element={
            <ProtectedElement>
              <ConnectFacebookAds />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.FACEBOOK_ADS_CREATE_SCREEN2.path}
          element={
            <ProtectedElement>
              <SelectFacebookAccount />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.FACEBOOK_ADS_CREATE_SCREEN3.path}
          element={
            <ProtectedElement>
              <SQLSchemaName />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.FACEBOOK_ADS_CREATE_SCREEN4.path}
          element={
            <ProtectedElement>
              <PermissionsVerified />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.FACEBOOK_ADS_CREATE_SCREEN5.path}
          element={
            <ProtectedElement>
              <YouareConnectedtoFacebookAds />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.API_CONNECTOR.path}
          element={
            <ProtectedElement>
              <CreateSource />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.SALESFORCE_AVAILABLE_FLOWS.path}
          element={
            <ProtectedElement>
              <AvailableFlows />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.TASKS.path}
          element={
            <ProtectedElement>
              <Tasks />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.CSV_MAPPINGS.path}
          element={
            <ProtectedElement>
              <FieldMapper />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.API_CONNECTOR_DETAILS.path}
          element={
            <ProtectedElement>
              <ApiConnectorDetails />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.CONNECTOR_SETTINGS.path}
          element={
            <ProtectedElement>
              <ConnectorSettings />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.MODEL_SETTINGS.path}
          element={
            <ProtectedElement>
              <ModelSettings />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.MODEL_QUERYBUILDER.path}
          element={
            <ProtectedElement>
              <ModelQueryBuilder />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.CONNECTOR_OVERVIEW.path}
          element={
            <ProtectedElement>
              <ConnectorOverview />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.CSV_OVERVIEW.path}
          element={
            <ProtectedElement>
              <CsvOverview />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.DESTINATION_OVERVIEW.path}
          element={
            <ProtectedElement>
              <DestinationOverview />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.MODEL_OVERVIEW.path}
          element={
            <ProtectedElement>
              <ModelOverview />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.MODEL_CREATION.path}
          element={
            <ProtectedElement>
              <ModelCreation />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.DATASETS.path}
          element={
            <ProtectedElement>
              <Datasets />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.UPLOADS.path}
          element={
            <ProtectedElement>
              <Uploads />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.DESTINATIONS.path}
          element={
            <ProtectedElement>
              <Destinations />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.EXPLORER.path}
          element={
            <ProtectedElement>
              <Explorer />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.MODEL.path}
          element={
            <ProtectedElement>
              <Model />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.AIMODEL_OVERVIEW.path}
          element={
            <ProtectedElement>
              <AIModelOverview />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.UNIFIED.path}
          element={
            <ProtectedElement>
              <Unified />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.SETTINGS.path}
          element={
            <ProtectedElement>
              <Settings />
            </ProtectedElement>
          }
        >
          <Route index element={<Profile />}></Route>
          <Route
            path={APP_ROUTES.YOUR_COMPANY.path}
            element={
              <ProtectedElement>
                <Company />
              </ProtectedElement>
            }
          />
          <Route
            path={APP_ROUTES.USER_MANAGEMENT.path}
            element={
              <ProtectedElement>
                <UserManagement />
              </ProtectedElement>
            }
          />

          <Route
            path={APP_ROUTES.PROFILE_SETTINGS.path}
            element={
              <ProtectedElement>
                <Profile />
              </ProtectedElement>
            }
          />
          <Route
            path={APP_ROUTES.SWITCH_ROLE.path}
            element={
              <ProtectedElement>
                <SwitchRole />
              </ProtectedElement>
            }
          ></Route>

          <Route
            path={APP_ROUTES.EDIT_NAVBAR.path}
            element={
              <ProtectedElement>
                <V3EditNavBar />
              </ProtectedElement>
            }
          ></Route>
          <Route
            path={APP_ROUTES.CHANGE_PASSWORD.path}
            element={
              <ProtectedElement>
                <ChangePassword />
              </ProtectedElement>
            }
          />
          <Route
            path={APP_ROUTES.QUIZ_FLOW.path}
            element={
              <ProtectedElement>
                <QuizFlow />
              </ProtectedElement>
            }
          />
          <Route
            path={APP_ROUTES.PORTFOLIO.path}
            element={
              <ProtectedElement>
                <Portfolio />
              </ProtectedElement>
            }
          />
          <Route
            path={APP_ROUTES.BILLING.path}
            element={
              <ProtectedElement>
                <Billing />
              </ProtectedElement>
            }
          />
          <Route
            path={APP_ROUTES.NOTIFICATION_SETTINGS.path}
            element={
              <ProtectedElement>
                <NotificationSettings />
              </ProtectedElement>
            }
          />
        </Route>
        <Route
          path={APP_ROUTES.PROPOSALS.path}
          element={
            <ProtectedElement>
              <Proposals />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.PLAYBOOKS.path}
          element={
            <ProtectedElement>
              <NewPlaybooks />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.CATEGORY_PLAYBOOKS.path}
          element={
            <ProtectedElement>
              <CategoryPlaybooks />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.PLAYBOOK_CATEGORIES.path}
          element={
            <ProtectedElement>
              <NewPlaybooks />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.MY_PLAYBOOKS.path}
          element={
            <ProtectedElement>
              <MyPlaybooks />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.PEOPLE.path}
          element={
            <ProtectedElement>
              <Chat />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.OPENED_CHAT.path}
          element={
            <ProtectedElement>
              <ChatUI />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.CREATE_WORKSPACE.path}
          element={
            <ProtectedElement>
              <CreateWorkspace />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.FIND_WORKSPACES.path}
          element={
            <ProtectedElement>
              <Marketplaces />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.CREATE_PLAYBOOK.path}
          element={
            <ProtectedElement>
              <CreatePlaybook />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.CREATE_B2B_PLAYBOOK.path}
          element={
            <ProtectedElement>
              <CreateB2BPlaybook />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.EDIT_PLAYBOOK_DETAILS.path}
          element={
            <ProtectedElement>
              <EditPlaybookDetails />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.EDIT_MARKETPLACE_DETAILS.path}
          element={
            <ProtectedElement>
              <EditMarketplace />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.PLAYBOOK_DETAILS.path}
          element={
            <ProtectedElement>
              <PlaybooksDetailPage />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.PREVIEW_PLAYBOOK_ADS.path}
          element={
            <ProtectedElement>
              <PreviewPlaybookAds />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.STEPS.path}
          element={
            <ProtectedElement>
              <StepsController />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.BUILD_LIST.path}
          element={
            <ProtectedElement>
              <BuildList />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.SELECT_YOUR_DESTINATIONS.path}
          element={
            <ProtectedElement>
              <SelectYourDestinations />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.SAVE_DATASET_DETAILS.path}
          element={
            <ProtectedElement>
              <SaveDatasetDetails />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.SELECT_SOURCE.path}
          element={
            <ProtectedElement>
              <SelectSource />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.TRANSFORM_DATA_SELECT_SOURCE.path}
          element={
            <ProtectedElement>
              <TransformDataSelectSource />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.TRANSFORM_DATA_PASTE_QUERY.path}
          element={
            <ProtectedElement>
              <TransformDataPasteQuery />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.PASTE_QUERY.path}
          element={
            <ProtectedElement>
              <PasteQuery />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.TRANSFORM_YOUR_DATA.path}
          element={
            <ProtectedElement>
              <TransformYourData />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.ADD_EXCLUSIONS.path}
          element={
            <ProtectedElement>
              <AddExclusions />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.EXCLUSIONS_SELECT_SOURCE.path}
          element={
            <ProtectedElement>
              <AddExclustionSelectSource />
            </ProtectedElement>
          }
        />
        <Route
          path={APP_ROUTES.EXCLUSIONS_PASTE_QUERY.path}
          element={
            <ProtectedElement>
              <AddExclusionsPasteQuery />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.TRANSFORM_DATA.path}
          element={
            <ProtectedElement>
              <PasteQuery />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.PREVIEW_YOUR_LIST.path}
          element={
            <ProtectedElement>
              <PreviewYourList />
            </ProtectedElement>
          }
        />

        <Route
          path={APP_ROUTES.PROPOSAL_DETAILS.path}
          element={
            <ProtectedElement>
              <ProposalDetails />
            </ProtectedElement>
          }
        />
      </Route>

      <Route
        path={APP_ROUTES.DEEPLINK_WEBSITE_WITHOUT_USER_ID.path}
        element={<DeeplinkExternal />}
      ></Route>
      <Route
        path={APP_ROUTES.DEEPLINK_WEBSITE_WITH_USER_ID.path}
        element={<DeeplinkExternal />}
      ></Route>
      <Route
        path={APP_ROUTES.DEEPLINK_PROPOSAL_WEBSITE_WITHOUT_USER_ID.path}
        element={<DeeplinkExternal />}
      ></Route>

      <Route
        path={APP_ROUTES.DEEPLINK_INVITE.path}
        element={<DeeplinkInvite />}
      ></Route>
      <Route
        path={APP_ROUTES.DEEPLINK_AUDIENCE_INVITE.path}
        element={<DeeplinkAudienceInvite />}
      ></Route>
      <Route
        path={APP_ROUTES.DEEPLINK_SOURCE_INVITE.path}
        element={<DeeplinkSourceInvite />}
      ></Route>
      <Route
        path={APP_ROUTES.DEEPLINK_WEBSITE_JOIN_MARKETPLACE.path}
        element={<DeeplinkExternal />}
      ></Route>

      <Route
        path={APP_ROUTES.DEEPLINK_PROPOSAL_CHAT_WEBSITE_WITH_USER_ID.path}
        element={<DeeplinkExternalProposalChat />}
      ></Route>
      <Route
        path={APP_ROUTES.DEEPLINK_EXTERNAL_SELLER_LISTING.path}
        element={<DeeplinkExternalSellerListing />}
      ></Route>

      <Route path="*" element={<Navigate to={APP_ROUTES.LANDING.path} />} />
    </Routes>
  );
}

function PublicElement({ children }) {
  const authReducer = useSelector((state) => state.authReducer);
  if (authReducer.isAuthenticated) {
    return <Navigate to={APP_ROUTES.DASHBOARD.path} />;
  }
  return children;
}
function ProtectedElement({ children }) {
  const authReducer = useSelector((state) => state.authReducer);
  if (!authReducer.isAuthenticated) {
    return <Navigate to={APP_ROUTES.LANDING.path} />;
  }
  return children;
}

export const APP_ROUTES = {
  LANDING: {
    path: "/",
  },

  SIGNIN: {
    path: "/signin",
  },
  SOCIAL_SIGNIN: {
    path: "/socialSignin",
  },
  WELCOME_BACK: {
    path: "/welcomeBack",
  },

  SIGNUP: {
    path: "/signup",
  },
  SIGNUP_INFO: {
    path: "/signupInfo",
  },
  SIGNUP_ADD_PHONE: {
    path: "/signupAddPhone",
  },
  SIGNUP_VERIFY_PHONE: {
    path: "/signupVerifyPhone",
  },
  FORGOT_PASSWORD: {
    path: "/forgotPassword",
  },
  RESET_PASSWORD: {
    path: "/resetPassword",
  },

  DATASETS: {
    path: "datasets/:id",
  },
  UPLOADS: {
    path: "datasets/sources",
  },

  DESTINATIONS: {
    path: "datasets/destinations",
  },
  EXPLORER: {
    path: "datasets/explorer",
  },
  MODEL: {
    path: "datasets/models",
  },
  AIMODEL_OVERVIEW: {
    path: "datasets/model/:modelName",
  },

  UNIFIED: {
    path: "datasets/explorer/:personName/:revId",
  },
  CATALOG_LIST: {
    path: "datasets/sources/catalogList",
  },
  DESTINATION_CATALOG_LIST: {
    path: "datasets/destination/catalogList",
  },
  SALES_FORCE: {
    path: "datasets/sources/salesforcemarketingcloud/:id",
  },
  LEADS_API_DESTINATION: {
    path: "datasets/destination/leadsapi/:id",
  },
  DATA_AXLE_DESTINATION: {
    path: "datasets/destination/dataaxle/:id",
  },
  ZERO_BOUNCE_DESTINATION: {
    path: "datasets/destination/zerobounce/:id",
  },
  SALES_FORCE_DESTINATION: {
    path: "datasets/destination/salesforcemarketingcloud/:id",
  },
  GOOGLE_DRIVE: {
    path: "datasets/sources/googleDrive/:id",
  },
  POSTGRES: {
    path: "datasets/sources/postgres/:id",
  },
  ZENDESK: {
    path: "datasets/sources/zendesk/:id",
  },
  LEADS_API: {
    path: "datasets/sources/leadsapi/:id",
  },
  FACEBOOKADS: {
    path: "datasets/sources/facebookads/:id",
  },
  MESSAGE_MEDIA: {
    path: "datasets/sources/messageMedia/:id",
  },
  SFTP: {
    path: "datasets/sources/sftp/:id",
  },
  API_CONNECTOR: {
    path: "datasets/sources/revcloudapievents/:id",
  },
  GOOGLE_ADS: {
    path: "datasets/sources/googleads/:id",
  },
  CSV_CATALOG: {
    path: "datasets/sources/csv/:id",
  },

  API_CONNECTOR_DETAILS: {
    path: "datasets/apiconnector/overview/:sourceId/:sourceName/:connectionName/:connectorId/:active",
  },

  CONNECTOR_SETTINGS: {
    path: "datasets/settings/:sourceId/:sourceName/:connectionName/:connectorId/:active",
  },
  MODEL_SETTINGS: {
    path: "model/settings/:modelId/:modelName",
  },
  MODEL_QUERYBUILDER: {
    path: "model/querybuilder/:modelId/:modelName",
  },
  CONNECTOR_OVERVIEW: {
    path: "datasets/connector/overview/:sourceId/:sourceName/:connectionName/:connectorId/:active",
  },
  CSV_OVERVIEW: {
    path: "datasets/connector/overview/csv/:sourceId/:sourceName/:connectionName/:connectorId/:active",
  },
  DESTINATION_OVERVIEW: {
    path: "datasets/destination/overview/:sourceId/:sourceName/:connectionName/:connectorId/:active",
  },
  MODEL_OVERVIEW: {
    path: "datasets/model/overview/:modelId/:modelName",
  },
  MODEL_CREATION: {
    path: "datasets/model/creation/:sourceId/:sourceName/:connectionName/:connectorId/:active",
  },

  SALESFORCE_AVAILABLE_FLOWS: {
    path: "/dashboard/datasets/sources/salesforce/availableFlows",
  },
  TASKS: {
    path: "/dashboard/datasets/sources/tasks/:sourceId/:sourceName/:connectionName/:connectorId/:active",
  },
  CSV_MAPPINGS: {
    path: "/dashboard/datasets/sources/mapping/:sourceId/:sourceName/:connectionName/:connectorId/:active",
  },
  PROCESSING_FIELDS: {
    path: "sources/setup/:sourceType/:sourceId",
  },
  DESTINATION_PROCESSING_FIELDS: {
    path: "destination/setup/:sourceType/:sourceId",
  },
  FACEBOOK_ADS_CREATE_SCREEN1: {
    path: "sources/setup/facebookAds/create/screen1",
  },
  FACEBOOK_ADS_CREATE_SCREEN2: {
    path: "sources/setup/facebookAds/create/screen2",
  },
  FACEBOOK_ADS_CREATE_SCREEN3: {
    path: "sources/setup/facebookAds/create/screen3",
  },
  FACEBOOK_ADS_CREATE_SCREEN4: {
    path: "sources/setup/facebookAds/create/screen4",
  },
  FACEBOOK_ADS_CREATE_SCREEN5: {
    path: "sources/setup/facebookAds/create/screen5",
  },
  CSV_ONBOARDING: {
    path: "datasets/sources/csv",
  },
  PROPOSALS: {
    path: "proposals",
  },
  PLAYBOOKS: {
    path: "playbooks",
  },

  PLAYBOOK_CATEGORIES: {
    path: "playbookCategories",
  },
  MY_PLAYBOOKS: {
    path: "myPlaybooks",
  },
  CREATE_WORKSPACE: {
    path: "createMarketplace",
  },
  FIND_WORKSPACES: {
    path: "findMarketplaces",
  },
  CREATE_PLAYBOOK: {
    path: "createPlaybook",
  },
  CREATE_B2B_PLAYBOOK: {
    path: "createB2bPlaybook",
  },
  EDIT_PLAYBOOK_DETAILS: {
    path: "editPlaybookDetails/:playbookId",
  },
  EDIT_MARKETPLACE_DETAILS: {
    path: "editMarketplace/:marketplaceId",
  },

  // Datasets URLs
  STEPS: {
    path: "datasets/datasets/steps",
  },
  BUILD_LIST: {
    path: "datasets/datasets/build-list",
  },
  SELECT_SOURCE: {
    path: "datasets/datasets/build-list/select-source",
  },
  PASTE_QUERY: {
    path: "datasets/datasets/build-list/paste-query",
  },
  TRANSFORM_YOUR_DATA: {
    path: "datasets/datasets/build-list/transform-your-data",
  },
  ADD_EXCLUSIONS: {
    path: "datasets/datasets/add-exclusions",
  },
  EXCLUSIONS_SELECT_SOURCE: {
    path: "datasets/datasets/add-exclusions/select-source",
  },
  EXCLUSIONS_PASTE_QUERY: {
    path: "datasets/datasets/add-exclusions/paste-query",
  },
  TRANSFORM_DATA: {
    path: "datasets/datasets/transform-data",
  },
  TRANSFORM_DATA_SELECT_SOURCE: {
    path: "datasets/datasets/transform-data/select-source",
  },
  TRANSFORM_DATA_PASTE_QUERY: {
    path: "datasets/datasets/transform-data/paste-query",
  },

  SELECT_YOUR_DESTINATIONS: {
    path: "datasets/datasets/select-your-destinations",
  },

  SAVE_DATASET_DETAILS: {
    path: "datasets/datasets/save-dataset-details",
  },

  PREVIEW_YOUR_LIST: {
    path: "datasets/datasets/preview-your-list",
  },

  DASHBOARD: {
    path: "/dashboard",
  },
  SETTINGS: {
    path: "settings",
  },
  YOUR_COMPANY: {
    path: "yourCompany",
  },
  USER_MANAGEMENT: {
    path: "userManagement",
  },
  PROFILE_SETTINGS: {
    path: "profileSettings",
  },
  CHANGE_PASSWORD: {
    path: "changePassword",
  },
  QUIZ_FLOW: {
    path: "onboardingQuiz",
  },
  NOTIFICATION_SETTINGS: {
    path: "notificationSettings",
  },
  PORTFOLIO: {
    path: "portfolio",
  },
  BILLING: {
    path: "billing",
  },
  BILLING: {
    path: "billing",
  },
  PEOPLE: {
    path: "people",
  },
  OPENED_CHAT: {
    path: "chat",
  },
  SWITCH_ROLE: {
    path: "switchRole",
  },
  EDIT_NAVBAR: {
    path: "editNavbar",
  },
  PLAYBOOK_DETAILS: {
    path: "playbookDetails/:playbookId",
  },
  PREVIEW_PLAYBOOK_ADS: {
    path: "PreviewPlaybookAds",
  },
  CATEGORY_PLAYBOOKS: {
    path: "category/playbooks/:playbookCategoryId",
  },
  PROPOSAL_DETAILS: {
    path: "proposalDetails/:playbookId",
  },
  DEEPLINK_WEBSITE_WITH_USER_ID: {
    path: "external/playbook/:marketplaceid/:categoryid/:playbookid/:userid",
  },
  DEEPLINK_WEBSITE_WITHOUT_USER_ID: {
    path: "external/playbook/:marketplaceid/:categoryid/:playbookid/",
  },
  DEEPLINK_PROPOSAL_WEBSITE_WITHOUT_USER_ID: {
    path: "external/requestProposal/:isDemo/:marketplaceid/:categoryid/:playbookid/:packageid",
  },
  DEEPLINK_INVITE: {
    path: "external/invite/:marketplaceid/:email/:role",
  },
  DEEPLINK_AUDIENCE_INVITE: {
    path: "/external/invite/audience/:id",
  },
  DEEPLINK_SOURCE_INVITE: {
    path: "/external/invite/source/:id",
  },
  DEEPLINK_WEBSITE_JOIN_MARKETPLACE: {
    path: "external/joinMarketplace/:isDemo/:marketplaceid",
  },
  DEEPLINK_PROPOSAL_CHAT_WEBSITE_WITH_USER_ID: {
    path: "external/proposalChat/:isDemo/:marketplaceid/:categoryid/:playbookid/:packageid/:userid",
  },
  DEEPLINK_EXTERNAL_SELLER_LISTING: {
    path: "external/seller-listing/:marketplaceid/:userid",
  },
};
/**
 * https://app.fidelityamericanhomes.com/external/proposalChat/495/21/201/428/336
 * https://app.fidelityamericanhomes.com/external/proposalChat/495/21/211/430/339
 */

/**
 * https://app.palisade.ai/external/playbook/496/33/153/
 * app.palisade.ai/external/playbook/marketplaceid/categoryid/playbookid/(userid,-1)
 */
/**
 * app.palisade.ai/external/requestProposal/marketplaceid/categoryid/playbookid/packageid/(userid,-1)
 */
export default AppRoutes;
