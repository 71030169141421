import React from "react";
import "./index.scss";
import { Image, Button } from "antd";
import { MenuOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { IconZeroBounce } from "images";

function ZeroBounceDestinationCatalog() {
  const navigate = useNavigate();
  const { id } = useParams();
  const navigateToFields = () => {
    navigate(`/dashboard/destination/setup/${"zerobounce"}/${id}`);
  };

  const handleBackClick = () => {
    // Navigate back to the previous page
    navigate(-1); // Go back one step
  };

  const handleConnectionClick = () => {
    // Navigate back to the previous page
    navigate(-2); // Go back one step
  };

  return (
    <div className="ZeroBounceDestinationCatalog">
      <div className="ZeroBounceDestinationCatalog__Fixed">
        <div className="ZeroBounceDestinationCatalog__sourceText">
          <div>
            <Image
              src={IconZeroBounce}
              preview={false}
              style={{ marginRight: "10px" }}
            />
            <span style={{ marginLeft: "15px" }}>Zero Bounce</span>
          </div>
        </div>
        <div className="ZeroBounceDestinationCatalog__bar">
          <div>
            <Button
              onClick={() => navigateToFields()}
              className="ZeroBounceDestinationCatalog__button"
            >
              Add Destination
              <ArrowRightOutlined className="ZeroBounceDestinationCatalog__btnImage" />
            </Button>
          </div>
        </div>
        <div className="SalesforceDestinationDetails__newSource">
          <Button className="SalesforceDestinationDetails__newText">
            Destination
          </Button>
        </div>
        <div className="ZeroBounceDestinationCatalog__exploreBar">
          <div className="ZeroBounceDestinationCatalog__exploreText">
            ZeroBounce Email Services, Email List Cleaning, Validation & More
          </div>
        </div>
        <div className="ZeroBounceDestinationCatalog__bottomBar"></div>
      </div>
      <div className="ZeroBounceDestinationCatalog__mainContainer">
        <div className="ZeroBounceDestinationCatalog__flexDiv">
          <div className="ZeroBounceDestinationCatalog__container1">
            <h6 className="ZeroBounceDestinationCatalog__cardHeading1">
              Compatible destination types
            </h6>
            <div className="ZeroBounceDestinationCatalog__textFlex">
              <div className="ZeroBounceDestinationCatalog__cardIcon">
                <MenuOutlined />
              </div>
              <div className="ZeroBounceDestinationCatalog__cardText">
                Storage
              </div>
            </div>
            <p className="ZeroBounceDestinationCatalog__rawText">
              Destinations for storing raw data.
              {/*<a className="ZeroBounceDestinationCatalog__anchorTag">Learn more</a>*/}
            </p>
          </div>
          <div className="ZeroBounceDestinationCatalog__container2">
            <div className="ZeroBounceDestinationCatalog__containFlex">
              <div className="ZeroBounceDestinationCatalog__line"></div>
              <div className="ZeroBounceDestinationCatalog__justDiv">
                <h6 className="ZeroBounceDestinationCatalog__cardHeading2">
                  Set up requirements
                </h6>
                <p className="ZeroBounceDestinationCatalog__setText1">
                  * A Zero Bounce Axle account with administrator privileges
                </p>
                {/* <p className="ZeroBounceDestinationCatalog__setText1">
                  * Ideally, the account should have View All Records
                  permission. If you aren’t sure, ask you Salesforce
                  administrator for help.
                </p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="ZeroBounceDestinationCatalog__flexDiv1">
          <div className="ZeroBounceDestinationCatalog__container3">
            <h6 className="ZeroBounceDestinationCatalog__overviewHeading1">
              Overview
            </h6>
            <p className="ZeroBounceDestinationCatalog__overviewText">
              Discover the power of ZeroBounce email services to supercharge your email campaigns and maximize your email marketing ROI. Clean your existing email list and discover new prospects with Email Finder. Ensure optimal email server configuration and monitor your sender reputation.
              Clean your email list fast and keep your subscribers up to date with ZeroBounce email validation. Upload your email list with our bulk upload tool, verify emails in real-time with the API, or integrate with 60+ third-party platforms. Get rid of invalid emails with 99% guaranteed accuracy, lower your email bounce rate, and hit the inbox reliably.
              Whether you just need a one-time email list cleaning or regular monthly assistance, ZeroBounce has you covered. When you sign up for a free account, you can use all ZeroBounce email services, including email list cleaning, Email Finder, email testing, and more.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ZeroBounceDestinationCatalog;
