import React from "react";
import "./index.scss";
import { Input, Button, message, Spin, Space, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import Flex from "screens/Chat/Flex";

import { Table } from "antd";
import { useState, useEffect } from "react";
import axios from "axios";
import { PlusOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { getSavedAccessToken } from "store/localStorage";
import { clearAuthState } from "store/modules/auth/action";
import { clearFileOnboardingState } from "store/modules/fileOnboard/action";
import { getCreatedSourcesList } from "api/Connectors";

const { Option } = Select;

function MyDestinations() {
  const userToken = getSavedAccessToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredNewSources, setFilteredNewSources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasRunOnce, setHasRunOnce] = useState(false);
  const [statusFilter, setStatusFilter] = useState("all");
  const apiUrl =
    "https://api.palisade.ai/connector/getSources";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  const tableContainerStyle = {
    height: "calc(100vh - 300px)", // Adjust the height as needed
    overflow: "auto",
  };

  const columns = [
    // {
    //     title: 'Id',
    //     dataIndex: ['client_source_id'],
    //     key: 'id',
    // },

    {
      title: "NAME",
      dataIndex: "source",
      key: "name",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={text.icon_url}
            alt="icon"
            style={{ width: 30, height: 24, marginRight: 10 }}
          />
          <div>
            <a
              // onClick={() => handleRowClick(record)}
              style={{
                cursor: "pointer",
                textDecoration: "none",
                transition: "transform 0.2s, text-decoration 0.2s",
                display: "inline-block",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = "scale(1.06)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = "scale(1)";
              }}
            >
              {record.name}
            </a>
          </div>
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "is_active",
      key: "active",
      render: (text) => (
        <div className="statusFlex">
          {/* Check if text is true */}
          {text === true && (
            <>
              <div className="Status"></div>
              <div>Enabled</div>
            </>
          )}
          {/* Check if text is false */}
          {text === false && (
            <>
              <div className="InActiveStatus"></div>
              <div>Disabled</div>
            </>
          )}
          {/* Handle other cases */}
          {typeof text !== "boolean" && (
            <>
              <div>Not applicable</div>
            </>
          )}
        </div>
      ),
    },

    {
      title: "CONNECTION TYPE",
      dataIndex: ["source", "source_type"],
      key: "type",
    },

    {
      title: "CATEGORY",
      dataIndex: ["source", "source_category"],
      key: "category",
    },
  ];

  const handleRowClick = (record) => {
    const sourceId = record.client_source_id;
    const connectionName = record.name;
    const sourceName = record?.source?.name;
    const active = record?.is_active;
    const connectorId = record?.source?.id;
    // navigate(
    //   `/dashboard/datasets/destination/overview/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
    // );
  };

  useEffect(() => {
    if (!hasRunOnce) {
      setHasRunOnce(true);
      getListOfSources();
    }
  }, [filteredData]);

  const getListOfSources = () => {
    setLoading(true);

    // const payload = {
    //   is_destination: true,
    // };

    // getCreatedSourcesList(payload)
    //   .then((response) => {
    //     console.log("Get Destinations List API Success", response);
    //     setFilteredData(response.data.data);
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     if (error.response.status == 403 || error.response.status == 401) {
    //       message.error(error?.response?.data?.message);
    //       dispatch(clearFileOnboardingState(null));
    //       dispatch(clearAuthState(null));
    //     } else {
    //       message.error(error?.response?.data?.message);
    //     }
    //   });

    // Construct the query string with the `isDestination` parameter
    const queryParams = new URLSearchParams({ isDestination: true }).toString();
    const urlWithParams = `${apiUrl}?${queryParams}`;

    axios
      .get(urlWithParams, { headers })
      .then((response) => {
        setFilteredData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 403 || error.response?.status === 401) {
          message.error(error?.response?.data?.message);
          dispatch(clearFileOnboardingState(null));
          dispatch(clearAuthState(null));
        } else {
          message.error(error?.response?.data?.message);
        }
      });
  };

  const handleStatusChange = (value) => {
    setStatusFilter(value);
  };

  const filterByStatus = (data) => {
    switch (statusFilter) {
      case "all":
        return data; // Show all items

      case "enabled":
        return data.filter((item) => item.is_active === true); // Show only enabled items

      case "disabled":
        return data.filter((item) => item.is_active === false); // Show only disabled items

      case "in-progress":
        return data.filter((item) => item.status === "in-progress"); // Show only items with status 'in-progress'

      case "no-recent-data":
        return data.filter((item) => item.status === "no-recent-data"); // Show only items with status 'no-recent-data'

      default:
        return []; // If no match, show nothing
    }
  };

  const filteredDataByStatus = filterByStatus(filteredData);

  const handleSearch = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchInput(inputValue);

    const filterData = (data, stateSetter) => {
      const filtered = data.filter((item) =>
        item.name.toLowerCase().includes(inputValue)
      );
      stateSetter(filtered);
    };

    filterData(filteredData, setFilteredNewSources);
  };

  const NavigateCatalogList = () => {
    navigate("/dashboard/datasets/destination/catalogList");
  };

  return (
    <>
      <div className="DestinationTable">
        {/* <div className="DestinationTable__headerText"> */}
        {/* <span><Image src={IconChevronLeft} preview={false} /></span> */}
        {/* <span className="DestinationTable__title">List of Destinations</span>
          </div> */}

        {/* <div className="DestinationTable__sourceText">
          <span>My destinations</span>
        </div> */}

        <div className="DestinationTable__bar">
          <Select
            className="DestinationTable__status-filter"
            defaultValue="all"
            onChange={handleStatusChange}
            style={{ width: 165 }} // Adjust width as needed
          >
            <Option value="all">
              <span className="Status all-icon"></span> All
            </Option>
            <Option value="enabled">
              <span className="Status enabled-icon"></span> Enabled
            </Option>
            <Option value="disabled">
              <span className="Status disabled-icon"></span> Disabled
            </Option>
            <Option value="in-progress">
              <span className="Status in-progress-icon"></span> In Progress
            </Option>
            <Option value="no-recent-data">
              <span className="Status no-recent-data-icon"></span> No Recent
              Data
            </Option>
          </Select>
          <Input
            className="DestinationTable__input"
            type="search"
            placeholder="Search for destinations by name"
            onChange={handleSearch}
            value={searchInput}
            prefix={<SearchOutlined style={{ color: "grey" }} />}
          />
        </div>

        <div className="DestinationTable__tableDiv" style={tableContainerStyle}>
          {loading ? ( // Show spinner if no data
            <div className="DestinationTable__loading-container">
              <Spin />
            </div>
          ) : (
            <Table
              columns={columns}
              dataSource={
                searchInput
                  ? filterByStatus(filteredNewSources)
                  : filteredDataByStatus
              }
              pagination={true}
              onRow={(record) => {
                return {
                  onClick: () => {
                    handleRowClick(record);
                  },
                  onMouseEnter: (e) => {
                    e.currentTarget.style.cursor = "pointer";
                  },
                  onMouseLeave: (e) => {
                    e.currentTarget.style.cursor = "default";
                  },
                };
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default MyDestinations;
